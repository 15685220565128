<template>
  <div
    class="flex h-full items-center tabular-nums"
    :class="`${params.justifyStart ? 'justify-start' : 'justify-end'} `"
    v-if="cellValue"
  >
    <span :class="`${rawDifference ? (rawDifference > 0 ? 'text-green-400' : 'text-red-400') : 'text-gray-500'}`">
      {{ cellValue }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { getCurrencySymbol, formatNumber } from '@/composeables/filters';
import { rounded } from '@/composeables/filters';

const props = defineProps({
  params: {
    type: Object,
    default: undefined
  }
});

const rawDifference = computed(() => {
  let metric_val = props.params.data[props.params.colDef.field.split('-')[0]];
  if (props.params.colDef.field.split('-')[0] == 'daily_sentiment') metric_val = parseFloat(metric_val).toFixed(0);
  const val = props.params.value;

  const format_type = props.params.colDef.format_type;
  if (format_type.includes('percent')) metric_val = metric_val * 100;
  if (!val) return null;
  const diff = metric_val - val;
  return diff;
});

const cellValue = computed(() => {
  if (rawDifference.value == 0) return 0;
  if (!rawDifference.value) return '-';
  const leading_sign = rawDifference.value > 0 ? '+' : '-';
  return `${leading_sign}${formatNumber(Math.abs(rawDifference.value))}`;
});
</script>
